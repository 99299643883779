import React from "react";
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import Datenschutz from "../components/Datenschutz_";
import Info from "../components/Info";

const DatenschutzPage = () => {
  return (
    <>
      <Helmet>
        <title>Datenschutz</title>
        <meta name='description' content='Datenschutzerklärung der Physiotherapie Praxis Tim Winthagen mit Infos rund um Ihre Behandlung'/>
        <meta name='keywords' content='Physiotherapie, Krankengymnastik, Manuelle Lymphdrainage, Fußreflexzonenmassage, Wärmetherapie, Kältetherapie, Medizinische Massagetherapie, Neurologische Behandlung, Bobath Erwachsene, Faszienbehandlung, Elektrotherapie, Ultraschall, Flossing, Gerätetraining, Hausbesuche, Düren'/>
        <meta property='og:title' content='Datenschutz'/>
        <meta property='og:type' content='Website'/>
        <meta property='og:description' content='Datenschutzerklärung der Physiotherapie Praxis Tim Winthagen mit Infos rund um Ihre Behandlung'/>
        <meta property='og:image' content=''/>
        <meta property='og:locale' content='de'/>
        <meta property='og:url' content='https://www.physiotherapie-winthagen.de/datenschutz'/>
        <link rel="canonical" href="https://www.physiotherapie-winthagen.de/datenschutz"/>
      </Helmet>
      <Layout>
          <Datenschutz></Datenschutz>
          <Info />
      </Layout>
    </>  
  )
}

export default DatenschutzPage